<template>
  <div class="app-main-body">
    <transition>
      <router-view/>
    </transition>
  </div>
</template>

<script>
export default {
  name: "AppMain",
}
</script>

<style scoped lang="less">
.app-main-body{
  position: relative;
  height: 100%;
  //height: calc(100% - 40px) ;
  //background: #ffffff;
  //padding: 20px;
}
</style>