<template>
  <el-tooltip :content="content" effect="dark" placement="bottom">
    <i
        @click="click"
        :class="[ isFullscreen ? 'iconfont jt-icon-tuichuquanping' : 'iconfont jt-icon-quanping2', ]"
    ></i>
  </el-tooltip>
</template>

<script>
import screenfull from "screenfull";
export default {
  name: "Screenfulls",
  data() {
    return {
      //全屏，默认为不展示全屏
      isFullscreen: false,
      content: "全屏",
    };
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.destroy();
  },
  // 初始化
  created() {
  },
  // 事件方法
  methods: {
    click() {
      if (!screenfull.isEnabled) {
        this.$message({
          message: "你的浏览器不支持全屏",
          type: "warning"
        });
        return false;
      }
      screenfull.toggle();
    },
    change() {
      this.isFullscreen = screenfull.isFullscreen;
      this.isFullscreen == false ? this.content = "全屏" : this.content = "退出全屏"
    },
    init() {
      if (screenfull.isEnabled) {
        screenfull.on("change", this.change);
      }
    },
    destroy() {
      if (screenfull.isEnabled) {
        screenfull.off("change", this.change);
      }
    },
  },
}
</script>

<style scoped lang="less">

</style>