<template>
  <el-breadcrumb spellcheck="/">
    <el-breadcrumb-item>
      <div class="spans">当前位置：</div>
    </el-breadcrumb-item>
    <!-- :to="{ path: breeadCrumbItem.path }" -->
    <el-breadcrumb-item class="no-redirect" v-for="breeadCrumbItem in breadCrumbList"
        :key="breeadCrumbItem.index">
      {{ breeadCrumbItem.meta.title }}
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
export default {
  name: "Bread",
  computed: {
    // 根据路由动态渲染面包屑文本
    breadCrumbList() {
      return this.$route.matched;
    },
  },
}
</script>

<style scoped lang="less">
.spans {
  height: 64px;
  line-height: 64px;
  font-size: 16px;
  cursor: default;
}

.no-redirect {
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

</style>