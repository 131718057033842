<template>
  <div class="logoDiv">
    <router-link to="/">
      <div v-show="!isCollapse" class="logo-title">
        <img :src="logoImg" alt="" />
        <span>{{compAlias }}</span>
      </div>
      <div v-show="isCollapse">
        <img :src="logoImg" alt="" class="img2" />
      </div>
    </router-link>
  </div>
</template>

<script>
import logoImg from '@/assets/images/logo.png'
export default {
  name: "Logo",
  computed: {
    // 控制左侧菜单栏logo状态
    isCollapse() {
      return this.$store.state.tab.isCollapse;
    },
    compAlias(){
      return this.$store.state.common.user.compAlias || '会议签到系统'
    },
    logoImg(){
      return this.$store.state.common.user.compIcon || logoImg
    }
  },
}
</script>

<style scoped lang="less">
.logoDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  margin-bottom: 6px;
  background: #002548;
  .img2 {
    max-height: 30px;
    width: 100%;
    max-width: 30px;
  }
  .logo-title{
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    span{
      padding-left: 10px;
      font-family: HuXiaoBoNanShenTi-2;
      text-shadow: 0px 5px 6px rgba(0,0,0,0.7);
    }
    img{
      width: 30px;
    }
  }
}

</style>