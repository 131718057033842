<template>
  <div class="spanIcon">
    <!-- 时钟 -->
<!--    <Clocks class="clocks" />-->
    <!-- 全屏 -->
    <Screenfulls class="screenfulls" />
    <!-- 数据展板 -->
    <router-link to="/echarts">
      <el-tooltip content="数据展板" effect="dark" placement="bottom">
        <i class="iconfont jt-icon-zhongduanjiankong"></i>
      </el-tooltip>
    </router-link>
  </div>
</template>

<script>
// import Clocks from "./Clocks";
import Screenfulls from "./Screenfulls";
export default {
  name: "FunCommon",
  components: {Screenfulls}
}
</script>

<style scoped lang="less">
.spanIcon {
  height: 64px;
  float: left;
  i:not(.el-d-jtDesc) {
    margin: 5px;
    font-size: 25px;
    position: relative;
    color: #ffffff;
    height: 64px;
    line-height: 64px;
    right: 12px;
    cursor: pointer;
  }
  .jt-icon-zhongduanjiankong {
    font-size: 23px !important;
  }
  .clocks {
    float: left;
    color: #fff;
    height: 100%;
    line-height: 64px;
    font-size: 15px;
    margin-right: 20px;
  }

}

</style>